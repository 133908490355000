/* @flow */
import '../vendors/preloadjs-NEXT'
import config from '../config'

export const LOAD_TYPE = {
  js: createjs.LoadQueue.JAVASCRIPT,
  image: createjs.LoadQueue.IMAGE,
}
export type LoadType = $Keys<typeof LOAD_TYPE>
export type Manifest = {
  id: string,
  src: string,
}
export type Queue = Manifest & {
  type: ?string,
  element: ?HTMLElement,
  result: any,
  isComplete: boolean,
}

export default class Loader {
  static PROGRESS = 'progress'
  static FILELOAD = 'fileload'
  static COMPLETE = 'complete'
  
  maxConnections: number = config.MAX_CONNECTIONS
  queues: Queue[] = []
  loader: any
  // fixme: preloadjsのイベントが消せない不具合がある疑惑なので自分で管理
  // FIXME: イベントが消えない・・・・・
  listeners: Map = new Map()
  
  constructor() {
    // xhrでダウンロード後に<img/>化される。その際にweb serverのアクセスログとしては2回アクセスログが残るが、
    // ブラウザキャッシュが有効であるのでxhr後のタグ生成時は304になる
    const xhr = !location.port // 開発環境かどうか
    // const xhr = false
    this.loader = new createjs.LoadQueue(xhr)
    this.loader.setMaxConnections(this.maxConnections)
    this.loader.on('fileload', this.fileload.bind(this))
    this.loader.on('error', this.error.bind(this))
  }
  
  has(listener) {
    return this.listeners.has(listener)
  }
  
  on(type, listener, once = false) {
    if (!this.has(listener)) {
      this.listeners.set(listener, type)
      this.loader.on(type, listener, null, once)
    }
  }
  
  one(type, listener) {
    if (!this.has(listener)) {
      this.on(type, listener, true)
      this.listeners.set(listener, type)
    }
  }
  
  // on ハンドラのevent.remove()
  // off(type, listener) {
  //   if (this.has(listener)) {
  //     this.loader.off(type, listener)
  //     this.listeners.delete(listener)
  //   }
  // }
  
  error(e) {
    console.error(e)
  }
  
  fileload(e: any) {
    const {item, loader} = e
    const queue = this.queues.find(q => q.id === item.id)
    if (queue) {
      queue.type = item.type
      queue.src = item.src
      queue.element = item.element
      queue.result = e.result
      queue.isComplete = true
    }
  };
  
  transformQueue(manifest: Manifest[]): Queue[] {
    return manifest.map((m: Manifest) => {
      return {
        ...m,
        type: '',
        element: null,
        isComplete: false,
      }
    })
  }
  
  load(manifest: Manifest[]) {
    return new Promise((resolve, reject) => {
      // unique
      manifest.map(m => {
        const a = this.queues.filter(q => q.id === m.id)
        if (!a.length) {
          this.queues.push(m)
        }
      })
      this.loader.loadManifest(manifest)
      this.on('complete', () => {
        return resolve()
      })
    })
  }
  
  getResult(id: string) {
    return this.loader.getResult(id)
  }
  
  getLoaded(images: string[]) {
    if (!images.length) {
      return true
    }
    return images.every(src => {
      return this.queues.some(q => q.src === src && q.isComplete)
    })
  }
  
  find(src: string): boolean {
    const preloadItems = this.queues
    const item = preloadItems.find(i => {
      // FIXME: プロトコルとポートをurlから削除して比較
      // 開発時にbrowserSync+Pjax時にURLが変わってしまう
      const itemSrc = i.src.replace('http:', '').replace(/(localhost):[0-9]{4}/, '$1')
      const elementSrc = src.replace('http:', '').replace(/(localhost):[0-9]{4}/, '$1')
      return itemSrc === elementSrc
    })
    return (item && item.type === LOAD_TYPE.image) ? item : null
  }
  
  
  get loaded(): boolean {
    return this.loader.loaded
  }
  
}
