/* @flow */
import Mediaquery from '../services/Mediaquery'

// FIXME: static methodやめる
export function zeroFill(length: number, number: number) {
  return (Array(length).join('0') + number).slice(-length);
}

export function sleep(msec: number) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

/**
 * FIXME: ここでいいのか
 */
export function getSrc(element: ?HTMLElement) {
  const mq = Mediaquery.create()
  const mode = mq.isDesktop() ? '-desktop' : ''
  return element.getAttribute(`data-src${mode}`) || element.getAttribute(`data-src`)
}

export default class Utils {

  static getParams(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search)
    return results === null
           ? ''
           : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  static getOriginUrl() {
    const {origin, protocol, hostname, port} = location
    return origin ? origin : `${protocol}//${hostname}${port && ':' + port}`
  }

  static getFullUrl() {
    const {pathname} = location
    return `${this.getOriginUrl()}/${pathname}`
  }

  // 近似値
  static closestNumber(counts, num) {
    return counts.reduce((prev, curr) => {
      return Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev
    })
  }

  // 近似値
  static closestIndex(counts, num) {
    const cn = this.closestNumber(counts, num)
    return counts.indexOf(cn)
  }

  /**
   * FIXME: ここでいいのか
   */
  static getSrc(element: ?HTMLElement) {
    const mq = Mediaquery.create()
    const mode = mq.isDesktop() ? '-desktop' : ''
    return (
      element.getAttribute(`data-src${mode}`) ||
      element.getAttribute(`data-src`)
    )
  }

  /**
   * hrefがhoge#accessになっているかどうか
   * @param href
   */
  static isCurrentHashLink(href: string): boolean {
    const {pathname} = location
    return pathname !== '/' && href.includes(pathname) && href.includes('#')
  }

  /**
   * dispatchEvent
   * @param type
   */
  static dispatchEvent(type: string, element: HTMLElement) {
    if (typeof Event === 'function') {
      event = new Event(type)
    } else {
      event = document.createEvent('Event')
      event.initEvent(type, true, true)
    }
    element.dispatchEvent(event)
  }

  static sleep(msec: number) {
    return new Promise(resolve => setTimeout(resolve, msec))
  }

  static isElement(obj: Object) {
    try {
      //Using W3 DOM2 (works for FF, Opera and Chrom)
      return obj instanceof HTMLElement
    }
    catch (e) {
      //Browsers not supporting W3 DOM2 don't have HTMLElement and
      //an exception is thrown and we end up here. Testing some
      //properties that all elements have. (works on IE7)
      return (
               typeof obj === "object"
             ) &&
             (
               obj.nodeType === 1
             ) && (
               typeof obj.style === "object"
             ) &&
             (
               typeof obj.ownerDocument === "object"
             )
    }
  }

  static uniqueID() {
    return Math.random().toString(36).substr(2, 9)
  }

  static base64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  static emit(type, element: HTMLElement) {
    let event = null
    if (typeof Event === 'function') {
      event = new Event(type)
    } else {
      event = document.createEvent('Event')
      event.initEvent(type, true, true)
    }
    element.dispatchEvent(event)
  }

  static regexpEscape(str) {
    return str.replace(/[\\[.+*?(){|^$]/g, "\\$&")
  }

  static async poll(fn: Function, condFn: Function, interval = 30, timeout = 1000) {
    let duration = 0
    let condition = false
    do {
      const s = new Date().getTime()
      fn()
      await Utils.sleep(interval)
      const e = new Date().getTime()
      duration += e - s
      condition = (duration < timeout) && !condFn()
    } while (condition)
    return Promise.resolve()
  }

  static replace(element: HTMLElement, attrName: string, key: string, val: string) {
    const name = element.getAttribute(attrName)
    const newName = name && name.replace(`\[\%${key}\%\]`, `[${val}]`)
    newName && element.setAttribute(attrName, newName)
  }

}
