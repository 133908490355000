/* @flow */
const PRODUCTION = process.env.NODE_ENV === 'production'
// const PRESENTATION = true

const MEDIA_QUERY = {
  phone: 'screen and (max-width: 991px)',
  desktop: 'screen and (min-width: 992px)',
}

const config: {} = {
  TIMEOUT: 120 * 1000, // preload.jsのXHR モードだけ
  APP_JS_HASH: APP_JS_HASH,
  JS_RELATIVE_URL: '/assets/js',
  MAX_CONNECTIONS: 10,// loader
  MEDIA_QUERY: {
    phone: 'screen and (max-width: 991px)',
    desktop: 'screen and (min-width: 992px)',
  },
  SPLASH_SCREEN_DURATION: 1000, // 最低でもこの時間は表示させる。初期化時間との差分
  SPLASH_SCREEN_SELECTOR: '.js-splashscreen',
  PRELOAD_SELECTOR: '.js-preload',
  PRELOAD_DEFINE_SELECTOR: '.js-preload-define',
  KV_DURATION: 4000,
}


export {
  MEDIA_QUERY,
  PRODUCTION,
}

export default config
