/* @flow */
import config from './config'
import Time from './lib/Time'
import Loader from './loader/Loader'
import Preloader from './loader/Preloader'

Time.create().start('init')

window.loader = new Loader() // PreLoadImage.jsで使う
const preloader = new Preloader({
  loader: window.loader,
  selector: config.SPLASH_SCREEN_SELECTOR,
  preloadSelector: config.PRELOAD_SELECTOR,
})
preloader.load()

const loaderSequence = async () => {
  const splash = document.documentElement.querySelector(config.SPLASH_SCREEN_SELECTOR)
  if (splash) {
    splash.style.height = `${window.innerHeight}px`
    splash.classList.add('show')
  }
}

loaderSequence()
