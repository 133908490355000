/* @flow */
import config from '../config'
import type {Queue} from './Loader'
import Loader, {LOAD_TYPE} from './Loader'
import Mediaquery from '../services/Mediaquery'
import {getSrc} from '../lib/Utils'

type Props = {
  loader: Loader,
  selector: string,
  preloadSelector: string,
}

export default class Preloader {
  
  isFirst: boolean = true
  loader: Loader
  percent: number = 0
  progressbar: HTMLElement
  mq: Mediaquery
  
  
  constructor({loader}: Props) {
    this.loader = loader
    this.loader.on(Loader.PROGRESS, e => this._progress(e.progress))
    this.loader.on(Loader.FILELOAD, this._fileload.bind(this))
    this.loader.on(Loader.COMPLETE, e => this._complete(e))
    
    this.mq = Mediaquery.create()
  }
  
  _getSrc(el: HTMLElement) {
    return getSrc(el)
  }
  
  _attachAppJs(scriptTag: HTMLElement) {
    const {head} = document
    head && head.appendChild(scriptTag)
  }
  
  _progress(percent: number) {
    this.percent = percent
  }
  
  _fileload(e: any) {
    const {item, loader} = e
    if (item.type === LOAD_TYPE.js) {
      this._attachAppJs(loader.getTag())
      // this.loader.load([
      //   ...this.manifest,
      // ])
    }
  }
  
  _complete(e) {
  }
  
  _getManifest() {
    const elements = [...document.querySelectorAll(config.PRELOAD_DEFINE_SELECTOR)]
    return elements.map(e => {
      const src = this._getSrc(e)
      return {
        element: e,
        id: src,
        src: src,
        loadTimeout: config.TIMEOUT,
      }
    })
  }
  
  on(...args: any[]) {
    this.loader.on(...args)
  }
  
  load() {
    this.manifest = this._getManifest()
    return this.loader.load([
      {
        id: 'app.js',
        src: `${config.JS_RELATIVE_URL}/app.js?v=${config.APP_JS_HASH}`,
        loadTimeout: config.TIMEOUT,
        type: LOAD_TYPE.js,
      },
      ...this.manifest,
    ])
  }
  
  get loaded(): boolean {
    return this.loader.loader.loaded
  }
  
  get queues(): Queue[] {
    return this.loader.queues
  }
  
}

